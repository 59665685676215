<template>
  <div class="job-profession">
    <span class="job-profession__title">{{ role.name }}</span>
    <JobProfessionItems
      :roleRegulations="role.regulations"
      @updateProfileByLike="$emit('updateProfileByLike')"
      @openTestByButton="openTestByButton"
    />
  </div>
</template>

<script>
const JobProfessionItems = () =>
  import("@/components/ProfileComponents/JobFolderComponents/JobProfession/JobProfessionItems");

export default {
  name: "JobProfession",
  mixins: [],
  props: {
    role: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: { JobProfessionItems },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openTestByButton(value) {
      this.$emit("openTestByButton", value);
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color";

.job-profession {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  margin-bottom: 25px;

  border-bottom: 1px solid $color-button-click3;

  &__title {
    font-weight: 400;
    line-height: 13px;
    font-size: 14px;
    color: $color-text-accent;
  }
}
</style>
